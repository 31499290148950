<template>
    <div class="init">
        <el-form :model="fromValue" label-width="100px" class="elForm" :rules="rules" ref='form_' label-position="top">
            <el-form-item :label="$t('agreement.text2')" prop="chain">
                <el-select v-model="fromValue.chain" filterable :placeholder="$t('inputTit.xuanze')">
                    <el-option v-for="item in chainList" :key="item.chain" :label="item.chain" :value="item.chain" />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('agreement.text3')" prop="protocol">
                <el-input v-model="fromValue.protocol" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('agreement.text4')" prop="protocolType">
                <el-select v-model="fromValue.protocolType" filterable :placeholder="$t('inputTit.xuanze')">
                    <el-option v-for="item in protocolTypeList" :key="item.value" :label="item.label"
                    :value="item.value" />
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import { userStore } from '@/store/user'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { getDataStyle } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        chain:'',protocolId:'',
        protocol: '',protocolType:'',
    })
    const protocolTypeList = ref([ ]);getDataStyle('protocol_type',(e)=>{protocolTypeList.value = e})
    const chainList = ref([])

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun'])

    
    Api_.getCoinList().then(res=>{
        if(res.data){
            chainList.value = res.data.records
        }
    })
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.protocolId
                    Api_.addAgreementObj(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateAgreementObj(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_,completeFun})
</script>