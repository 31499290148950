import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const object = [{
    type: 'select',
    name: t('agreement.text2'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type: 'input',
    name: t('agreement.text3'),
    prop: 'protocol'
},{
    type: 'select',
    name: t('agreement.text4'),
    prop: 'protocolType',
    isParms: 'protocol_type'
},{
    type: 'datetime',
    name: t('agreement.text5'),
    prop: 'createTime'
}]