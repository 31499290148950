
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    chain: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    protocol: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    protocolType: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
}
export default{
    mainRules
}