import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = {
        border: true,
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        height: 'auto',
        align: 'center',
        viewBtn: false,
        columnBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        refreshBtn: false,
        menu:false,
        column: [ {
            width: 100,
            label: t('agreement.text2'),
            prop: 'chain',
        }, {
            width: 130,
            label:  t('agreement.text3'),
            prop: 'protocol',
        }, {
            width: 160,
            label: t('agreement.text4'),
            prop: 'protocolType',
            type: 'select',
            props: {
                label: "label",
                value: "value"
            },
            dicData: [{ label:  t('agreement.text4_1'), value: 0 }, 
                { label: 'FT', value: 1 },
                { label: 'NFT', value: 2 }
            ],
        },{
            label: t('agreement.text5'),
            prop: 'createTime',
        },{
            label: t('chain.text10'),
            prop: 'updateTime',
        },{
            label: t('chain.text11'),
            prop: 'updateUser',
        },{
            label: t('agreement.text7'),
            prop: 'status',
            addDisplay: false,
            type: 'select',
            editDisplay: false,
            props: {
                label: "label",
                value: "value"
            },
            dicData: [{
                label: t('agreement.text7_1'),
                value: 0
            }, 
             {
                label: t('agreement.text7_2'),
                value: 1
            }],
        },{
            width: 140,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
          }]
    }